<template>
  <div class="col-12 py-5 text-center">
    <b-icon icon="arrow-clockwise" animation="spin"
            variant="info"
            font-scale="6"></b-icon>
    <div class="w-100 py-4"></div>
    <h3 class="text-info">Loading........</h3>
  </div>
</template>

<script>
export default {
  name: 'PageLoader'
}
</script>

<style scoped>

</style>

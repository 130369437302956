<template>
  <fixed-header :threshold="80">
    <div class="container-fluid pageHeader" >
      <div class="row" >
        <div class="container">
          <div class="row" >
            <div class="col-auto py-2">
              <router-link :to="$store.state.homeLink" class="brandLogo">
                <img :src="$store.state.settings.logo" alt="hosbooking.net" />
              </router-link>
            </div>
            <div class="col py-2">
              <ul class="mainMenu">
                <li>
                  <router-link to="/check" class="">
                    {{$t('checkBooking')}}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fixed-header>
</template>

<script>
import FixedHeader from 'vue-fixed-header'
export default {
  name: 'MainHeader',
  components: {
    FixedHeader
  }
}
</script>

<style scoped>
.brandLogo img {
  height: 50px; margin: 0; display: block;
}
.pageHeader.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;
}
</style>
